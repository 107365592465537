import { FC, useState } from 'react'
import clsx from 'clsx'
import styles from './ShareBar.module.css'
import { BasicStyledComponent } from 'shared-definitions/types'
import ThreeDotsShare from 'shared-svg/ThreeDotsShare.svg'
import { useOutsideClick } from '../hooks/use-outside-click'
import dynamic from 'next/dynamic'

const VIafouraShareBar = dynamic(() => import('../service/VIafouraShareBar'))

const ShareBar: FC<BasicStyledComponent> = ({ className }) => {
  const [isShareBarVisible, setIsShareBarVisible] = useState(false)
  const ref = useOutsideClick<HTMLDivElement>(() => {
    setIsShareBarVisible(false)
  }, [])
  return (
    <div className={clsx(styles.container, className)} ref={ref}>
      <button
        type="button"
        className={styles.showButton}
        onClick={() => setIsShareBarVisible(s => !s)}
      >
        <ThreeDotsShare className={styles.showButtonIcon} />
      </button>
      <VIafouraShareBar
        className={clsx(styles.viafouraShareBar, {
          [styles.viafouraShareBarVisible]: isShareBarVisible,
        })}
      />
    </div>
  )
}

export default ShareBar
