import { useEffect, useRef } from 'react'

function useViafouraWidgetReset(): React.RefObject<HTMLElement> {
  const ref = useRef<HTMLElement>(null)
  useEffect(() => {
    if (ref.current) {
      void window.vf?.context.resetWidget(ref.current)
    }
  }, [])

  return ref
}

export default useViafouraWidgetReset
