/* eslint-disable react/self-closing-comp */
import clsx from 'clsx'
import { FC, useEffect, useState } from 'react'
import { BasicStyledComponent } from 'shared-definitions/types'
import Check from 'shared-svg/Check.svg'
import RWButton from '../buttons/RWButton'
import { useMutationObserver } from '../hooks/use-mutation-observer'
import useViafouraWidgetReset from '../hooks/useViafouraWIdgetReset'
import styles from './ViafouraFollow.module.css'

interface ViafouraFollowProps extends BasicStyledComponent {
  id: string
  label: string
  type?: string
  showCount?: boolean
  minimumCount?: number
}

// docs: https://documentation.viafoura.com/docs/custom-follow
const ViafouraFollow: FC<ViafouraFollowProps> = ({
  className,
  id,
  label,
  type,
  showCount = false,
  minimumCount,
}) => {
  const [isFollowing, setIsFollowing] = useState(false)
  const widgetRef = useViafouraWidgetReset()

  const boxRef = useMutationObserver<HTMLDivElement>(
    {
      characterData: true,
      childList: true,
      subtree: true,
    },
    checkButtonStatus
  )

  useEffect(checkButtonStatus, [])

  function checkButtonStatus(): void {
    if (boxRef.current) {
      const vfButton = boxRef.current.querySelector('button')
      if (vfButton && vfButton.classList.contains('vf-topic-unfollow-button')) {
        setIsFollowing(true)
      } else {
        setIsFollowing(false)
      }
    }
  }

  function proxyClick(): void {
    if (boxRef.current) {
      const vfButton = boxRef.current.querySelector('button')
      if (vfButton) {
        vfButton.click()
      }
    }
  }

  return (
    <div className={clsx(className)}>
      <div className={clsx('viafoura', styles.vfContainer)} ref={boxRef}>
        <vf-topic-follow
          ref={widgetRef}
          topic-id={id}
          topic-name={label}
          topic-type={type}
          show-count={showCount ? 'true' : 'false'}
          minimum-count={minimumCount}
        ></vf-topic-follow>
      </div>
      {isFollowing && (
        <RWButton
          size="medium"
          variant="secondary"
          onClick={proxyClick}
          className={styles.followed}
        >
          {label}
          <Check className={styles.followedIcon} />
        </RWButton>
      )}
      {!isFollowing && (
        <RWButton size="medium" variant="tertiary" onClick={proxyClick} className={styles.follow}>
          {label}
          <span className={styles.followIcon}>{'+'}</span>
        </RWButton>
      )}
    </div>
  )
}

export default ViafouraFollow
